var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { ProfileContext } from '__state/Profile';
import { getChannelByURL } from 'services';
import './WatchParty.scss';
import LoginPopover from 'components/LoginPopover';
import ChatScreen from './screens/Chat';
import InviteButton from '../../__assets/images/button__invite.svg';
import ChatButton from '../../__assets/images/button__chat.svg';
import MultiUsersButton from '../../__assets/images/button__multi-users.svg';
import Members from './screens/Members';
var SCREENS = {
    INVITE: 'invite',
    CHAT: 'chat',
    MEMBERS: 'members',
};
var baseClass = 'page__watchparty';
var WatchParty = function (_a) {
    var _b = _a.location.state, partyName = _b.partyName, sendbirdURL = _b.sendbirdURL;
    var profile = useContext(ProfileContext).profile;
    var _c = useState(SCREENS.CHAT), screen = _c[0], setScreen = _c[1];
    // get watch party chat data
    var _d = useQuery(['groupChat', sendbirdURL], function () {
        return getChannelByURL(sendbirdURL);
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    }), groupChat = _d.data, chatError = _d.error;
    var renderScreen = function () {
        var chatOpts = {
            baseClass: baseClass,
            profile: profile,
            groupChat: groupChat,
            chatError: chatError,
        };
        var membersOpts = {
            baseClass: baseClass,
            groupChat: groupChat,
        };
        switch (screen) {
            case SCREENS.MEMBERS:
                return _jsx(Members, __assign({}, membersOpts), void 0);
            default:
                return _jsx(ChatScreen, __assign({}, chatOpts), void 0);
        }
    };
    return (_jsxs("div", __assign({ className: baseClass }, { children: [_jsx("div", __assign({ className: baseClass + "__header" }, { children: partyName }), void 0), _jsxs("div", __assign({ className: baseClass + "__misc-header" }, { children: [_jsx("button", __assign({ title: "Invite fans", className: baseClass + "__hbutton " + baseClass + "__hbutton--invite-fans", onClick: function () { return setScreen(SCREENS.INVITE); } }, { children: _jsx("img", { alt: "", src: InviteButton }, void 0) }), void 0), _jsx("button", __assign({ title: "Chat", className: baseClass + "__hbutton " + baseClass + "__hbutton--chat", onClick: function () { return setScreen(SCREENS.CHAT); } }, { children: _jsx("img", { alt: "", src: ChatButton }, void 0) }), void 0), _jsxs("button", __assign({ className: baseClass + "__hbutton " + baseClass + "__hbutton--fan-count", onClick: function () { return setScreen(SCREENS.MEMBERS); } }, { children: [_jsx("img", { alt: "", src: MultiUsersButton }, void 0), groupChat === null || groupChat === void 0 ? void 0 : groupChat.joinedMemberCount] }), void 0)] }), void 0), renderScreen(), !profile && _jsx(LoginPopover, { partyToJoin: groupChat }, void 0)] }), void 0));
};
export default WatchParty;
