import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import { createRouter, scroll } from '@curi/router';
import { browser } from '@hickory/browser';
import { createRouterComponent } from '@curi/react-dom';
import routes from './routes';
import * as API from './services';
import App from './App';
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
var router = createRouter(browser, routes, {
    sideEffects: [scroll()],
    external: {
        API: API
    }
});
var Router = createRouterComponent(router);
// to make sure the render happens ONLY when it receives a response
router.once(function () {
    ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Router, { children: _jsx(App, {}, void 0) }, void 0) }, void 0), document.getElementById('root'));
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
