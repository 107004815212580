export var emojiKeys = {
    LIKE: 'sendbird_emoji_thumbsup',
    DISLIKE: 'sendbird_emoji_thumbsdown',
};
export var NFL_PARTIES_2021 = {
    development: [
        {
            _id: '5f97001f7755aa4554e970e4',
            name: 'Broncos @ Chargers with Bleav in LA Football',
            sendbirdURL: 'sendbird_group_channel_7608_813de4db65618ae45feaf4eff174795ab2482fb3',
            profileImage: 'https://media.giphy.com/media/2Uy0gekYd7T4fAM0LB/giphy.gif',
        },
        {
            _id: '5f97001f7755aa4554e970e7',
            name: 'Seahawks @ 49ers',
            sendbirdURL: 'sendbird_group_channel_7608_f03f1480b3a7af8f1da30d1b27331bbbd16c621c',
            profileImage: 'https://media.giphy.com/media/2aChNP9hu1fo6IfSd0/giphy.gif',
        },
        {
            _id: '5f97001f7755aa4554e970e8',
            name: 'Eagles @ Cowboys with Korner Booth (Pre-Game)',
            sendbirdURL: 'sendbird_group_channel_7608_153edecaf7630c24a7f49a8c50b2060954f486e5',
            profileImage: 'https://media.giphy.com/media/o7yaK6xAJyp6ehBv4a/giphy.gif',
        },
    ],
    staging: [
        {
            _id: '5f97001f7755aa4554e970fc',
            name: 'Eagles @ Cowboys with Korner Booth',
            sendbirdURL: 'sendbird_group_channel_7608_ee2a7abc30e8fbea56155e16dddedc61d63997d7',
            profileImage: 'https://media.giphy.com/media/l3vRcru6ZI1mzpdwA/giphy.gif',
        },
        {
            _id: '5f97001f7755aa4554e970fd',
            name: 'New! Rams @ Dolphins with Bleav in LA Football',
            sendbirdURL: 'sendbird_group_channel_7608_9a893a4ee5898b93dd8583926525ddc70bd7a216',
            profileImage: 'https://media.giphy.com/media/3o6Zt4PjVca9KnaosU/giphy.gif',
        },
        {
            _id: '5f97001f7755aa4554e970fe',
            name: 'Giants @ Buccaneers with PewterCast',
            sendbirdURL: 'sendbird_group_channel_7608_2887d2690f1e0fc03ef9593d90f0c05b1860c252',
            profileImage: 'https://media.giphy.com/media/l4JySuIpxwHHSj6Eg/giphy.gif',
        },
    ],
    production: [
        {
            _id: '5facc21b377e9d34fb1d6aef',
            name: 'Seahawks Fans Only with @houseofseahawks',
            sendbirdURL: 'sendbird_group_channel_7608_813de4db65618ae45feaf4eff174795ab2482fb3',
            profileImage: 'https://media.giphy.com/media/2Uy0gekYd7T4fAM0LB/giphy.gif',
        },
        {
            _id: '5facc0f4377e9d34fb1d6ae7',
            name: 'Raiders Fans Only!!',
            sendbirdURL: 'sendbird_group_channel_7608_f03f1480b3a7af8f1da30d1b27331bbbd16c621c',
            profileImage: 'https://media.giphy.com/media/2aChNP9hu1fo6IfSd0/giphy.gif',
        },
        {
            _id: '',
            name: 'Ravens Fans Watch Party',
            sendbirdURL: 'sendbird_group_channel_7608_153edecaf7630c24a7f49a8c50b2060954f486e5',
            profileImage: 'https://media.giphy.com/media/o7yaK6xAJyp6ehBv4a/giphy.gif',
        },
        {
            _id: '',
            name: 'Buccaneers Watch Party',
            sendbirdURL: 'sendbird_group_channel_7608_a9e649c03ff819f33e76370dd7d536bcd7334a86',
            profileImage: 'https://media.giphy.com/media/2E6OYt2yphlWTGZeYK/giphy.gif',
        },
        {
            _id: '',
            name: 'Bills Mafia Offseason Chat!',
            sendbirdURL: 'sendbird_group_channel_7608_dd5a793678a1289decdd30d68c1c1cce7f5243e0',
            profileImage: 'https://media.giphy.com/media/iFxjM028eHYkei5rEM/giphy.gif',
        },
        {
            _id: '',
            name: 'Steelers Room!',
            sendbirdURL: 'sendbird_group_channel_7608_3972d4c330885d093170a23927838a44d66779e2',
            profileImage: 'https://media.giphy.com/media/l3vRcru6ZI1mzpdwA/giphy.gif',
        },
        {
            _id: '',
            name: 'Packers Fans',
            sendbirdURL: 'sendbird_group_channel_7608_059760c0990acd486e593385d01b5a067433ca62',
            profileImage: 'https://media.giphy.com/media/l4JySuIpxwHHSj6Eg/giphy.gif',
        },
        {
            _id: '',
            name: 'Michigan Watch Party',
            sendbirdURL: 'sendbird_group_channel_7608_f9a09dd2a857cfff86790c1bdf25f8cecfdea39d',
            profileImage: 'https://media.giphy.com/media/3o6Zt4PjVca9KnaosU/giphy.gif',
        },
    ],
};
