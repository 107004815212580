var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '@curi/react-dom';
import { useState } from 'react';
import { NFL_PARTIES_2021 } from 'utils/constants';
import './Home.scss';
import Logo from '../../__assets/images/logo.png';
var baseClass = "page__home";
var env = process.env.REACT_APP_ENV || 'development';
var Home = function () {
    // const [parties, setParties] = useState<IWatchParty[]>([]);
    var _a = useState(NFL_PARTIES_2021[env]), parties = _a[0], setParties = _a[1];
    // useEffect(() => {
    //   const retrieveParties = async () => {
    //     try {
    //       const { data }: AxiosResponse = await getParties();
    //       const { watchPartyListing }: IWatchPartyListResponse = data;
    //       setParties(watchPartyListing);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    //   retrieveParties();
    // }, []);
    return (_jsxs("div", __assign({ className: baseClass }, { children: [_jsx("div", __assign({ className: baseClass + "__header" }, { children: _jsx("img", { alt: "Collyde", src: Logo }, void 0) }), void 0), _jsx("div", __assign({ className: baseClass + "__party-list" }, { children: parties.map(function (_a, i) {
                    var _id = _a._id, name = _a.name, profileImage = _a.profileImage, sendbirdURL = _a.sendbirdURL;
                    return (_jsxs(Link, __assign({ name: "Party", params: { partyId: _id }, state: { partyName: name, sendbirdURL: sendbirdURL }, className: baseClass + "__party-row", "data-is-last": i === parties.length - 1 }, { children: [_jsx("div", __assign({ className: baseClass + "__party-photo" }, { children: _jsx("div", __assign({ className: "photo-wrapper" }, { children: _jsx("div", { style: { backgroundImage: "url('" + profileImage + "')" } }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: baseClass + "__party-title" }, { children: name }), void 0)] }), _id));
                }) }), void 0)] }), void 0));
};
export default Home;
