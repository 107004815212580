var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { getMessages, sendMessage, watchMessages, sendGif } from 'services';
import { useDeepEffect } from 'utils/helpers';
import ContentEditable from 'react-contenteditable';
import ChatMessage from 'components/ChatMessage';
import SendMessageButton from '../../../__assets/images/button__send-message.svg';
;
var Chat = function (_a) {
    var baseClass = _a.baseClass, profile = _a.profile, groupChat = _a.groupChat, chatError = _a.chatError;
    var _b = useState(''), typedMessage = _b[0], setTypedMessage = _b[1];
    var _c = useState(''), gifSearch = _c[0], setGifSearch = _c[1];
    var _d = useState(), loadedGifs = _d[0], setLoadedGifs = _d[1];
    var _e = useState(''), selectedGif = _e[0], setSelectedGif = _e[1];
    var _f = useState(false), isGifDrawerOpen = _f[0], setIsGifDrawerOpen = _f[1];
    var _g = useState(false), isSending = _g[0], setIsSending = _g[1];
    var giphy = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY);
    // get messages
    var _h = useQuery(['messages', groupChat === null || groupChat === void 0 ? void 0 : groupChat.url], function () { return getMessages(groupChat); }, {
        enabled: !!groupChat,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    }), messagesStatus = _h.status, messages = _h.data, messagesError = _h.error, refetchMessages = _h.refetch;
    // get gifs
    useQuery(['gifs', gifSearch], function () {
        return giphy.search(gifSearch || 'sports', {
            rating: 'pg-13',
            limit: 25,
        });
    }, {
        enabled: isGifDrawerOpen,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: function (data) {
            console.log(data);
            setLoadedGifs(data);
        }
    });
    useDeepEffect(function () {
        if (messages) {
            var chat = document.querySelector("." + baseClass + "__chat-container");
            if (chat) {
                chat.scrollTop = chat ? chat.scrollHeight : 0;
            }
        }
    }, [messages]);
    useDeepEffect(function () {
        if (groupChat) {
            watchMessages(groupChat, refetchMessages);
        }
    }, [groupChat]);
    var handleNewMessage = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var formatted, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (isSending) {
                        return [2 /*return*/, false];
                    }
                    setIsSending(true);
                    if (!groupChat) {
                        console.log('Something is super wrong because we didn\'t get the chat data!');
                        return [2 /*return*/, false];
                    }
                    if (!profile) {
                        console.log('Something is super wrong because we didn\'t get the profile data!');
                        return [2 /*return*/, false];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, 7, 8]);
                    if (!!!selectedGif) return [3 /*break*/, 3];
                    return [4 /*yield*/, sendGif(selectedGif, groupChat)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    formatted = typedMessage.replace(/\&nbsp\;/gi, '');
                    return [4 /*yield*/, sendMessage(formatted, groupChat)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    refetchMessages({ throwOnError: false, cancelRefetch: false });
                    setTypedMessage('');
                    setSelectedGif('');
                    setIsGifDrawerOpen(false);
                    return [3 /*break*/, 8];
                case 6:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 8];
                case 7:
                    setIsSending(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var handleGifDrawer = function () {
        if (isGifDrawerOpen) {
            setIsGifDrawerOpen(false);
        }
        else {
            setIsGifDrawerOpen(true);
        }
    };
    var renderMessage = function () {
        if (!!selectedGif) {
            return (_jsx("div", __assign({ className: "gif-input" }, { children: _jsxs("div", __assign({ className: "selected-gif" }, { children: [_jsx("img", { alt: "", src: selectedGif }, void 0), _jsx("button", __assign({ onClick: function () { return setSelectedGif(''); } }, { children: "\u2716" }), void 0)] }), void 0) }), void 0));
        }
        return (_jsx(ContentEditable, { html: typedMessage, placeholder: "Message", onFocus: function () { return setIsGifDrawerOpen(false); }, onChange: function (e) { return setTypedMessage(e.target.value); }, className: "message-input" }, void 0));
    };
    if (chatError) {
        return (_jsx("p", { children: "Something went wrong gathering the party's information. Please refresh the page to try again." }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: baseClass + "__chat-container" }, { children: [messagesStatus === "loading" && _jsx("p", { children: "Messages loading..." }, void 0), messagesStatus !== "loading" &&
                        messages &&
                        messages.map(function (m, i) { return (_jsx(ChatMessage, { message: m, groupChat: groupChat, appUserId: profile === null || profile === void 0 ? void 0 : profile._id, isLast: i === messages.length - 1 }, i)); }), !messages && messagesError && (_jsx("p", { children: "Something went wrong gathering the party's messages. Please refresh the page to try again." }, void 0))] }), void 0), profile && (_jsxs("div", __assign({ className: baseClass + "__chat-message-box" }, { children: [_jsx("div", __assign({ className: "typing-area" }, { children: renderMessage() }), void 0), _jsxs("div", __assign({ className: "action-area" }, { children: [_jsx("div", __assign({ role: "button", className: "gif-button", onClick: handleGifDrawer }, { children: "GIF" }), void 0), _jsx("div", __assign({ role: "button", className: "send-button", onClick: handleNewMessage }, { children: _jsx("img", { alt: "Send message", src: SendMessageButton }, void 0) }), void 0)] }), void 0)] }), void 0)), isGifDrawerOpen && (_jsxs("div", __assign({ className: baseClass + "__gif-drawer" }, { children: [_jsx(ContentEditable, { html: gifSearch, placeholder: "Search Gifs", onChange: function (e) { return setGifSearch(e.target.value); }, className: "gif-input" }, void 0), _jsx("div", __assign({ className: "gif-chooser" }, { children: loadedGifs === null || loadedGifs === void 0 ? void 0 : loadedGifs.data.map(function (_a) {
                            var id = _a.id, _b = _a.images, preview_gif = _b.preview_gif, fixed_height_small = _b.fixed_height_small;
                            if (preview_gif && fixed_height_small) {
                                return (_jsx("div", __assign({ role: "button", className: "gif-image", onClick: function () { return setSelectedGif(fixed_height_small.url); } }, { children: _jsx("img", { alt: "", src: preview_gif.url }, void 0) }), id));
                            }
                        }) }), void 0)] }), void 0))] }, void 0));
};
export default Chat;
