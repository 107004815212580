import { prepareRoutes } from "@curi/router";
import Home from "pages/Home";
import Party from "pages/WatchParty";
export default prepareRoutes([
    {
        name: "Home",
        path: "",
        respond: function () {
            return { body: Home };
        },
    },
    {
        name: "Party",
        path: "party/:partyId",
        // resolve(matched, external) {
        //   return external.API.getPartyInfo(matched?.params.partyId);
        // },
        // respond({ resolved }) {
        //   return {
        //     body: Party,
        //     data: resolved.data,
        //   };
        // },
        respond: function () {
            return { body: Party };
        },
    },
    // {
    //   name: 'NotFound',
    //   path: '(.*)',
    //   respond() {
    //     return {
    //       body: NotFound
    //     };
    //   }
    // },
]);
