var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useResponse } from "@curi/react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProfileProvider from '__state/Profile';
import "./App.scss";
var App = function () {
    var queryClient = new QueryClient();
    var response = useResponse().response;
    var Body = response.body, data = response.data, location = response.location;
    return (_jsx("div", __assign({ className: "app-wrapper" }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(ProfileProvider, { children: _jsx(Body, { data: data, location: location }, void 0) }, void 0) }), void 0) }), void 0));
};
export default App;
