var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { pullAllBy } from 'lodash';
;
var Members = function (_a) {
    var baseClass = _a.baseClass, groupChat = _a.groupChat;
    if (!groupChat || !process.env.REACT_APP_SB_ADMIN_USER_ID) {
        return null;
    }
    var realMembers = groupChat.members;
    pullAllBy(realMembers, [{ userId: process.env.REACT_APP_SB_ADMIN_USER_ID }], 'userId');
    return (_jsx("div", __assign({ className: baseClass + "__members" }, { children: realMembers.map(function (_a, i) {
            var profileUrl = _a.profileUrl, nickname = _a.nickname, userId = _a.userId;
            return (_jsxs("div", __assign({ className: "member", "data-is-last": i === realMembers.length - 1 }, { children: [_jsx("div", __assign({ className: "user-photo" }, { children: _jsx("div", __assign({ className: "photo-wrapper" }, { children: _jsx("div", { style: { backgroundImage: "url('" + profileUrl + "')" } }, void 0) }), void 0) }), void 0), _jsx("p", __assign({ className: "user-name" }, { children: nickname }), void 0)] }), userId));
        }) }), void 0));
};
export default Members;
