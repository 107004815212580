import SendBird from "sendbird";
import { getItemFromLocalStorage } from 'utils/helpers';
import { emojiKeys } from 'utils/constants';
var previousMessageQuery = null;
var currentSbUser = null;
var sbId = process.env.REACT_APP_SENDBIRD_APP_ID || '';
var currentAppUser = getItemFromLocalStorage('profile', null);
var currentUserId = (currentAppUser === null || currentAppUser === void 0 ? void 0 : currentAppUser._id) || process.env.REACT_APP_SB_ADMIN_USER_ID || '';
var sb = new SendBird({ appId: sbId });
export var connectSendbird = function (callback) {
    if (currentSbUser && typeof callback === 'function') {
        return callback();
    }
    sb.connect(currentUserId, function (user, error) {
        if (error) {
            return Promise.reject(error);
        }
        currentSbUser = user;
        if (typeof callback === 'function') {
            return callback();
        }
        return Promise.resolve();
    });
};
export var getChannelByURL = function (channelURL) {
    return new Promise(function (resolve, reject) {
        connectSendbird(function () {
            sb.GroupChannel.getChannel(channelURL, function (groupChannel, error) {
                if (error) {
                    reject(error);
                }
                resolve(groupChannel);
            });
        });
    });
};
export var getMessages = function (groupChannel) {
    var params = new sb.MessageListParams();
    params.prevResultSize = 50;
    params.nextResultSize = 50;
    params.isInclusive = true;
    params.reverse = false;
    params.includeReplies = true;
    params.includeThreadInfo = false;
    params.includeParentMessageText = false;
    params.includeReactions = true;
    return new Promise(function (resolve, reject) {
        var timestamp = Date.now();
        groupChannel.getMessagesByTimestamp(timestamp, params, function (messages, error) {
            if (error) {
                reject(error);
            }
            resolve(messages);
        });
    });
};
export var getMessageList = function (groupChannel) {
    if (!previousMessageQuery) {
        previousMessageQuery = groupChannel.createPreviousMessageListQuery();
    }
    previousMessageQuery.limit = 50;
    previousMessageQuery.reverse = false;
    previousMessageQuery.includeReaction = true;
    return new Promise(function (resolve, reject) {
        if (previousMessageQuery.hasMore && !previousMessageQuery.isLoading) {
            previousMessageQuery.load(function (messageList, error) {
                error ? reject(error) : resolve(messageList);
            });
        }
        else {
            resolve([]);
        }
    });
};
export var sendMessage = function (message, groupChannel) {
    var params = new sb.UserMessageParams();
    params.message = message;
    // params.mentionType = 'users';                       // Either 'users' or 'channel'
    // params.mentionedUserIds = ['Jeff', 'Julia'];        // Or mentionedUsers = Array<User>;
    return new Promise(function (resolve, reject) {
        connectSendbird(function () {
            groupChannel.sendUserMessage(params, function (userMessage, error) {
                if (error) {
                    reject(error);
                }
                resolve(userMessage);
            });
        });
    });
};
export var sendGif = function (url, groupChannel) {
    var params = new sb.FileMessageParams();
    params.fileUrl = url;
    return new Promise(function (resolve, reject) {
        connectSendbird(function () {
            groupChannel.sendFileMessage(params, function (fileMessage, error) {
                if (error) {
                    reject(error);
                }
                resolve(fileMessage);
            });
        });
    });
};
export var watchMessages = function (groupChannel, refetch) {
    var channelHandler = new sb.ChannelHandler();
    if (typeof refetch !== 'function') {
        throw new Error('Fix this.');
    }
    channelHandler.onMessageReceived = function () {
        refetch();
    };
    channelHandler.onReactionUpdated = function () {
        console.log('refetching');
        refetch();
    };
    sb.addChannelHandler(groupChannel.url, channelHandler);
};
// export const watchReactions = (groupChannel: GroupChannel, userMessage: UserMessage, ) => {
//   const channelHandler = new sb.ChannelHandler();
//   channelHandler.onReactionUpdated = (_, reactionEvent: ReactionEvent) => {
//     userMessage.applyReactionEvent(reactionEvent);
//   };
//   sb.addChannelHandler(groupChannel.url, channelHandler);
// };
export var likeOrDislikeMessage = function (groupChannel, userMessage, isLike, isAdd) {
    var reactionEmoji = isLike ? emojiKeys.LIKE : emojiKeys.DISLIKE;
    if (isAdd) {
        return new Promise(function (resolve, reject) {
            connectSendbird(function () {
                groupChannel.addReaction(userMessage, reactionEmoji, function (reactionEvent, error) {
                    if (error) {
                        reject(error);
                    }
                    userMessage.applyReactionEvent(reactionEvent);
                    resolve(true);
                });
            });
        });
    }
    return new Promise(function (resolve, reject) {
        connectSendbird(function () {
            groupChannel.deleteReaction(userMessage, reactionEmoji, function (reactionEvent, error) {
                if (error) {
                    reject(error);
                }
                userMessage.applyReactionEvent(reactionEvent);
                resolve(true);
            });
        });
    });
};
