import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
export var useDeepEffect = function (fn, deps) {
    var isFirst = useRef(true);
    var prevDeps = useRef(deps);
    useEffect(function () {
        var isSame = prevDeps.current.every(function (obj, index) {
            return isEqual(obj, deps[index]);
        });
        if (isFirst.current || !isSame) {
            fn();
        }
        isFirst.current = false;
        prevDeps.current = deps;
    }, [deps, fn]);
};
export var getItemFromLocalStorage = function (key, defaultVal) {
    var item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultVal;
};
